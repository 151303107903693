.logoValkhanTech {
    display: flex;
    justify-content: center;
    div {
        align-items: center;
        img {
            width: 256px;
            height: 256px;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
    
}