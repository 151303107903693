// Importe as variáveis de CSS comuns
@import '../../css/variables';

#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;

  &.header-scrolled,
  &.header-inner-pages {
    background: rgba(40, 58, 90, 0.9);
  }

  .logo {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #fff;
    span {
      padding-left: 60px;
      @media (max-width: 768px){
        padding-left: 0px;
        width: 100vw;
        display: block;
        text-align: center;
      }
    }

    img {
      max-height: 50px;
      position: absolute;
      margin-top: -12px
    }
  }

}



/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

// Desktop Navigation
.navbar {
  padding: 0;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;

    li {
      position: relative;

      a,
      a:focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 10px 30px;
        font-size: 15px;
        font-weight: 500;
        color: $text-white;
        white-space: nowrap;
        transition: 0.3s;

        i {
          font-size: 12px;
          line-height: 0;
          margin-left: 5px;
        }
      }

      a:hover,
      .active,
      .active:focus,
      li:hover>a {
        color: $vlk-blue;
      }
    }

    .getstarted,
    .getstarted:focus {
      padding: 8px 20px;
      margin-left: 30px;
      border-radius: 50px;
      color: $text-default;
      font-size: 14px;
      border: 2px solid $vlk-blue;
      font-weight: 600;

      &:hover,
      &:focus:hover {
        color: $text-white;
        background: $vlk-blue;
      }
    }

    .dropdown ul {
      display: block;
      position: absolute;
      left: 14px;
      top: calc(100% + 30px);
      margin: 0;
      padding: 10px 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      background: $text-white;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: 0.3s;
      border-radius: 4px;

      li {
        min-width: 200px;

        a {
          padding: 10px 20px;
          font-size: 14px;
          text-transform: none;
          font-weight: 500;
          color: #0c3c53;

          i {
            font-size: 12px;
          }
        }

        a:hover,
        .active:hover,
        li:hover>a {
          color: $vlk-blue;
        }
      }
    }

    .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }

    .dropdown .dropdown ul {
      top: 0;
      left: calc(100% - 30px);
      visibility: hidden;
    }

    .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: 100%;
      visibility: visible;
    }

    @media (max-width: 1366px) {
      .dropdown .dropdown ul {
        left: -90%;
      }

      .dropdown .dropdown:hover>ul {
        left: -100%;
      }
    }
  }
}

