@import "variables";

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
  
    &.section-bg {
      background-color: #f3f5fa;
    }
  
    .section-title {
      text-align: center;
      padding-bottom: 30px;
  
      h2 {
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;
        color: $theme-bg-color;
  
        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 120px;
          height: 1px;
          background: #ddd;
          bottom: 1px;
          left: calc(50% - 60px);
        }
  
        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 40px;
          height: 3px;
          background: $vlk-blue-dark;
          bottom: 0;
          left: calc(50% - 20px);
        }
      }
  
      p {
        margin-bottom: 0;
      }
    }
  }
  