@import "../../css/variables";
@import "../../css/section";

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
    .content {
      padding: 60px 100px 0 100px;
  
      h3 {
        font-weight: 400;
        font-size: 34px;
        color: #37517e;
      }
  
      h4 {
        font-size: 20px;
        font-weight: 700;
        margin-top: 5px;
      }
  
      p {
        font-size: 15px;
        color: #848484;
      }
    }
  
    .img {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../../img/why-us.png");
  
      @media (max-width: 992px) {
        min-height: 400px;
      }
  
      @media (max-width: 575px) {
        min-height: 200px;
      }
    }
  
    .accordion-list {
      padding: 0 100px 60px 100px;
  
      ul {
        padding: 0;
        list-style: none;
  
        li+li {
          margin-top: 15px;
        }
  
        li {
          padding: 20px;
          background: #fff;
          border-radius: 4px;
  
          button {
            display: block;
            position: relative;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            padding-right: 30px;
            outline: none;
            cursor: pointer;
            border: none;
            background: transparent;
  
            span {
              color: #47b2e4;
              font-weight: 600;
              font-size: 18px;
              padding-right: 10px;
            }
  
            i {
              font-size: 24px;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
  
          p {
            margin-bottom: 0;
            padding: 10px 0 0 0;
          }
  
          button.collapsed {
            color: #343a40;
  
            &:hover {
              color: #47b2e4;
            }
  
            .icon-show {
              display: inline-block;
            }
  
            .icon-close {
              display: none;
            }
          }
  
          .icon-show {
            display: none;
          }
        }
      }
    }
  
    @media (max-width: 1024px) {
      .content,
      .accordion-list {
        padding-left: 0;
        padding-right: 0;
      }
    }
  
    @media (max-width: 992px) {
      .content {
        padding-top: 30px;
      }
  
      .accordion-list {
        padding-bottom: 30px;
      }
    }
  }
  