@import "../../css/variables";

.cnpjProductCards {
    background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9));
    padding: 120px 0;

    .cardsContainer {
        display: flex;
        justify-content: center;
        justify-content: space-around;
        .card {
            .card-title {
                color: $vlk-magenta-dark;
                text-transform: uppercase;
            }

            .imgContainer {
                position: relative;
                width: 100%;

                i {
                    font-size: 30pt;
                    color: $text-default;
                }

                .imgOverlay {
                    position: absolute;
                    font-size: 30pt;
                    background: $vlk-green;
                    color: $text-default;
                    bottom: 0;
                    right: 0;
                    padding: 0.5rem 1rem;
                    border-radius: 1rem 0 0 1rem;
                }

                color: $text-default;
            }

            .buyAction {
                display: flex;
                justify-content: center;

                .btn-buy {
                    font-family: "Jost", sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                    letter-spacing: 1px;
                    display: inline-block;
                    padding: 12px 40px;
                    border-radius: 50px;
                    transition: 0.5s;
                    margin: 10px;
                    background-color: rgba($vlk-green, 0.5);
                    border: 2px solid rgba($vlk-green-dark, 0.5);
                    color: $text-default;
                    text-transform: uppercase;

                    &:hover {
                        background: $vlk-green;
                        border: 2px solid $vlk-green;
                    }
                }
            }

        }
    }
}