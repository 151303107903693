// Cores Valkhan:
$vlk-blue: #33C3F3;
$vlk-blue-dark: #00A0D0;
$vlk-green: #33F3C3;
$vlk-green-dark: #00D0A0;
$vlk-magenta: #C333F3;
$vlk-magenta-dark: #A000D0;


// Cores do tema:

$text-default: #444444;
$text-white: #fff;
$theme-bg-color: #37517e;


