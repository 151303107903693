@import "../../css/variables";

.services {
    .icon-box {
        box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
        padding: 50px 30px;
        transition: all ease-in-out 0.4s;
        background: #fff;

        h4 {
            font-weight: 500;
            margin-bottom: 15px;
            font-size: 24px;
            color: $vlk-magenta-dark;
            transition: ease-in-out 0.3s;
        }

        p {
            line-height: 24px;
            font-size: 14px;
            margin-bottom: 0;
        }

        &:hover {
            transform: translateY(-10px);

            h4 {
                color: $vlk-blue-dark;
            }
        }
    }
}