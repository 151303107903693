@import '../../css/variables';

#hero {
    width: 100%;
    height: 80vh;
    background: $theme-bg-color;
  
    .container {
      padding-top: 72px;
  
      h1 {
        margin: 0 0 10px 0;
        font-size: 48px;
        font-weight: 700;
        line-height: 56px;
        color: #fff;
      }
  
      h2 {
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 50px;
        font-size: 24px;
      }
  
      .btn-get-started {
        font-family: "Jost", sans-serif;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 1px;
        display: inline-block;
        padding: 10px 28px 11px 28px;
        border-radius: 50px;
        transition: 0.5s;
        margin: 10px 0 0 0;
        color: #fff;
        background: $vlk-blue;
  
        &:hover {
          background: $vlk-green-dark;
        }
      }
  
      .btn-watch-video {
        font-size: 16px;
        display: flex;
        align-items: center;
        transition: 0.5s;
        margin: 10px 0 0 25px;
        color: #fff;
        line-height: 1;
  
        i {
          font-size: 32px;
          margin-right: 8px;
          transition: 0.3s;
        }
  
        &:hover i {
          color: $vlk-blue;
        }
      }
  
      .animated {
        animation: up-down 2s ease-in-out infinite alternate-reverse both;
      }
    }
  }
  
  @media (max-width: 991px) {
    #hero {
      height: 100vh;
      text-align: center;
  
      .animated {
        animation: none;
      }
  
      .hero-img {
        text-align: center;
  
        img {
          width: 50%;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    #hero h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    #hero h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  
    #hero .hero-img img {
      width: 70%;
    }
  }
  
  @media (max-width: 575px) {
    #hero .hero-img img {
      width: 80%;
    }
  
    #hero .btn-get-started {
      font-size: 16px;
      padding: 10px 24px 11px 24px;
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
  
    100% {
      transform: translateY(-10px);
    }
  }
  