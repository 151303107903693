@import "../../css/variables";

.team {
  .member {
    position: relative;
    display: block;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 5px;
    background: $text-white;
    transition: 0.5s;
    height: 100%;

    .pic {
      overflow: hidden;
      width: 180px;
      height: 180px;
      border-radius: 50%;

      img {
        transition: ease-in-out 0.3s;
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      transform: translateY(-10px);
    }

    .member-info {
      padding-left: 30px;

      h4 {
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 20px;
        color: $theme-bg-color;
      }

      span {
        display: block;
        font-size: 15px;
        padding-bottom: 10px;
        position: relative;
        font-weight: 500;

        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 50px;
          height: 1px;
          background: #cbd6e9;
          bottom: 0;
          left: 0;
        }
      }

      p {
        margin: 10px 0 0 0;
        font-size: 14px;
      }

      .social {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        a {
          transition: ease-in-out 0.3s;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          width: 32px;
          height: 32px;
          background: #eff2f8;

          i {
            color: $theme-bg-color;
            font-size: 16px;
            margin: 0 2px;
          }

          &:hover {
            background: $vlk-blue;

            i {
              color: $text-white;
            }
          }

          &+a {
            margin-left: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .member .pic {
      min-width: 75px;
      width: 75px;
      height: 75px;

    }
  }

}

.footer-bottom {
  background-color: $theme-bg-color;
  padding-top: 30px;
  padding-bottom: 30px;
  color: $text-white;

  @media (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.copyright {
  text-align: center;
}

.credits {
  float: right;
  font-size: 8px;

  a {
    transition: 0.3s;
  }

  @media (max-width: 768px) {
    text-align: center;
    float: none;
    padding-top: 4px;
  }
}