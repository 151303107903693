@import "../../css/variables";

.cta {
    background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9));
    padding: 120px 0;
  
    h3 {
      color: #fff;
      font-size: 28px;
      font-weight: 700;
    }
  
    p {
      color: #fff;
    }

    strong {
        color: $vlk-green;
    }
  
    .cta-btn {
      font-family: "Jost", sans-serif;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 1px;
      display: inline-block;
      padding: 12px 40px;
      border-radius: 50px;
      transition: 0.5s;
      margin: 10px;
      border: 2px solid #fff;
      color: #fff;
  
      &:hover {
        background: $vlk-blue;
        border: 2px solid $vlk-blue;
      }
    }
  
    @media (max-width: 1024px) {
      background-attachment: scroll;
    }
  
    @media (min-width: 769px) {
      .cta-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  