@import "../../css/variables";

.pricing {
  .row {
    padding-top: 40px;
  }

  .box {
    padding: 50px 30px;
    box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
    background: $text-white;
    height: 100%;
    border-top: 4px solid $text-white;
    border-radius: 5px;

    h3 {
      font-weight: 500;
      margin-bottom: 15px;
      font-size: 20px;
      color: $vlk-blue-dark;
      text-transform: uppercase;
    }

    h4 {
      font-size: 48px;
      color: $vlk-blue-dark;
      font-weight: 400;
      font-family: "Jost", sans-serif;
      margin-bottom: 25px;

    }

    ul {
      padding: 20px 0;
      list-style: none;
      color: #999;
      text-align: left;
      line-height: 20px;

      li {
        padding: 10px 0 10px 30px;
        position: relative;

        i {
          color: $vlk-green-dark;
          font-size: 24px;
          position: absolute;
          left: 0;
          top: 6px;
        }

      }
    }

    &.featured {
      border-top-color: $vlk-blue;


    }

    &.item4 {
      border-top-color: $vlk-green !important;
    }
  }

  .listas {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .listaItem {
      width: 30vw;
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 992px) {
    .box {
      max-width: 60%;
      margin: 0 auto 30px auto;
    }
  }

  @media (max-width: 767px) {
    .box {
      max-width: 80%;
      margin: 0 auto 30px auto;
    }

    .listas .listaItem {
      width: 94vw;
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 420px) {

    .box {
      max-width: 100%;
      margin: 0 auto 30px auto;
    }

    .listas .listaItem {
      width: 94vw;
      margin-bottom: 2rem;
    }
  }


}